<template>
  <div class="profile">
    <div class="container rel">
      <div class="left" v-if="$store.getters.AuthUser">
        <div class="profile-img-container">
            <svg-icon class="logo-symbol" name="default-avatar"/>
        </div>
        <div>
        <div class="flex w-100 fa-cent fj-tween">
          <p>Display Name : <em>{{ $store.getters.AuthUser.displayName}}</em></p>
          <i class="fa-solid fa-pencil icon-edit"></i>
        </div>
        <div class="flex w-100 fa-cent fj-tween">
          <p>Email : <em>{{ $store.getters.AuthUser.email}}</em></p>
        </div>
          <p>Registered : <em>{{ $store.getters.AuthUser.created_at.split('T')[0]}} at {{ $store.getters.AuthUser.created_at.split('T')[1].split('Z')[0].slice(0,5) }}</em></p>
          <p>Last connection : <em>{{ $store.getters.AuthUser.last_sign_in_at.split('T')[0]}} at {{  $store.getters.AuthUser.last_sign_in_at.split('T')[1].split('Z')[0].slice(0,5) }}</em></p>
          <p>User ID <em> {{$store.getters.AuthUser.id}} </em></p>
          <!-- <p>Role : <em>{{ $store.getters.AuthUser.role}}</em></p> -->
        </div>
        <hr>
        <div class="">
          <div class="w-100 flex fj-tween">
            <p class="">**********</p>
            <form @submit.prevent="CHANGE_USER_EMAIL(email)" v-on:keyup.13="CHANGE_USER_EMAIL(email)">
              <div class="">
                <button type="submit" name="button" @click="CHANGE_USER_EMAIL(email)">Change email
                </button>
              </div>
            </form>
          </div>
          <div class="w-100 flex fj-tween">
            <p class="">**********</p>
            <form class="w-100 flex fj-tween" @submit.prevent="CHANGE_PASS(pass)" v-on:keyup.13="CHANGE_PASS(pass)">
              <div class="">
                <button type="submit" name="button" @click="CHANGE_PASS(pass)">Change password</button>
              </div>
            </form>
          </div>
          <hr>
          <div class="w-100 flex fj-tween">
            <p class="">Log out</p>
            <form>
              <div class="">
                <button type="submit" name="button" @click="LOG_USER_OUT()">Log Out</button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="right" v-if="$store.getters.AuthUser">
        <div class="flex fd-col fj-start fa-start">
        <h1>Sprites</h1>
          <table class="profile-sprite-table">
            <thead>
              <tr>
                <td>name</td>
                <td>key</td>
                <td>URLs authorized</td>
              </tr>
            </thead>
            <tbody>
              <tr class="table-row rel" v-for="(sprite, spriteKey) in $store.getters.UserSprites" :key="spriteKey" v-if="$store.getters.UserSprites"
              :class="{'--selected': spriteKey == userSpritesIndex}">
                <td>{{ sprite.name }}</td> 
                <td>{{ sprite.unique_key.slice(0,18) }}-...</td>
                <div class="table-url-list flex fd-col fj-start fa-start rel" v-for="(url, urlKey) in sprite.urls_allowed" :key="`url-${urlKey}`"
                >
                
                  <td class="table-url-item flex w-100 fj-tween fa-start" v-if="sprite.urls_allowed">
                  {{ url }} 
                    <span @click="deleteUrl($event, spriteKey, urlKey)">
                      <i class="fa-solid fa-xmark delete-icon pointer"></i>
                    </span>
                  </td>
                </div>
                <div class="table-url-plus pointer"
                @click="($event) => selectElement($event, spriteKey)">+</div>
                <div class="table-url-field">
                  <input type="url" name="url" :id="`url-${spriteKey}`" ref="field"
                  @keydown.enter="($event) => validateUrl($event, spriteKey)">
                </div>
                <div class="table-url-validate pointer"
                @click="($event) => validateUrl($event, spriteKey)"
                >add</div>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from '@/store/store.js'
import Container from '@/components/Container.vue'
import { supabase } from "@/supabase.js"

export default {
  name: 'profile',
  data(){
    return {
      userSpritesIndex: null,
    }
  },
  components:{
    Container,
  },
  methods: {
    LOG_USER_OUT(){
    },
    CHANGE_USER_EMAIL(email){
    },
    CHANGE_PASS(email){
    },
    async selectElement($event, index){
      await (this.userSpritesIndex = index)
      this.$refs.field[this.userSpritesIndex].focus()
    },
    makeActiveIndex(index){
      this.userSpritesIndex = index
    },
    resetActiveIndex(){
      this.userSpritesIndex = null
    },
    async validateUrl($event, spriteKey){
      // console.log(this.$refs.field[spriteKey].value)
      let sprite = this.$store.getters.UserSprites[spriteKey]
      if(this.$refs.field[spriteKey].value !== '' && this.$refs.field[spriteKey].value !== null){
        
        if(sprite.urls_allowed){
            const { data, error } = await supabase
              .from('sprites')
              .update({
                urls_allowed: [
                  ...sprite.urls_allowed,
                  this.$refs.field[spriteKey].value
                ]
              })
              .eq('id', sprite.id)
            if(error){
              console.log(error)
            }
            else if (data) {
              this.$toast(
                `sprite ${sprite.unique_key}, '${sprite.name}' sucessfully updated.`,
                {
                  timeout: 6000,
                  icon: 'fas fa-file-arrow-up',
                }
              )
            }
          
        }
        else {

            const { data, error } = await supabase
              .from('sprites')
              .update({
                urls_allowed: [
                  this.$refs.field[spriteKey].value
                  ]
              })
              .eq('id', sprite.id)
            if(error){
              console.log(error)
            }
            else if (data) {
              this.$toast(
                `sprite ${sprite.unique_key}, '${sprite.name}' sucessfully updated.`,
                {
                  timeout: 6000,
                  icon: 'fas fa-file-arrow-up',
                }
              )
            }

        }
        
      }
      else {
        await this.$toast(
          `Url field cannot be empty. Please enter Url`,
          {
            timeout: 6000,
            icon: 'fas fa-file-arrow-up',
          }
        )
      }
      
      this.userSpritesIndex = null
      this.$refs.field[spriteKey].blur()
    },
    async deleteUrl($event, spriteKey, urlKey){
      // sprite.urls_allowed = sprite.urls_allowed.splice(urlKey, 0)
      let sprite = await JSON.parse(JSON.stringify(this.$store.getters.UserSprites[spriteKey]))
      sprite.urls_allowed.splice(urlKey, 1)
      
      console.log('sprite.urls_allowed ====> ', sprite.urls_allowed)
      console.log('spriteKey ====> ', spriteKey)
      console.log('urlKey ====> ', urlKey)
      
      if(sprite.urls_allowed.length > 0){
        
        const { data, error } = await supabase
          .from('sprites')
          .update({
            urls_allowed: sprite.urls_allowed
          })
          .eq('id', sprite.id)
          
          if(error){
            console.log(error)
          }
          else if (data) {
            this.$toast(
              `sprite ${sprite.unique_key}, '${sprite.name}' sucessfully updated.`,
              {
                timeout: 6000,
                icon: 'fas fa-file-arrow-up',
              }
            )
          }
      
      }
      else {
        
        const { data, error } = await supabase
          .from('sprites')
          .update({
            urls_allowed: null
          })
          .eq('id', sprite.id)
          
          if(error){
            console.log(error)
          }
          else if (data) {
            this.$toast(
              `sprite ${sprite.unique_key}, '${sprite.name}' sucessfully updated.`,
              { timeout: 6000, icon: 'fas fa-file-arrow-up',}
            )
          }
      }
    },
  },
  async mounted(){
    supabase
    .from('sprites')
    .on('*', (payload) => {
      console.log('payload ===>', payload)
      this.$store.commit('updateUserSpriteUrlsAllowed', payload)
      this.$forceUpdate()   
    })
    .subscribe()
    
  }
}
</script>

<style>
.profile hr{
  width: 100%;
  margin: calc( var(--space-unit) * 4) 0;
  border: 0;
  border-top: 1px dotted var(--over-spread);
}
.profile {
  padding-top: calc(var(--space-unit)* 12);
  padding-bottom: calc(var(--space-unit)* 25);
}
.profile .container {
  position: relative;
  column-gap: calc(var(--space-unit)* 4);
  display: flex;
  width: calc(100% - 64px);
  margin: 0 auto;
}
.profile .left {
  position: sticky;
  top: 0;
  max-width: 480px;
}
.profile .right {
  width : fit-content;
  padding-left: calc(var(--space-unit)*2);
  border-left: 1px dotted var(--color-o-spread);
}
.profile-img-container {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 32px;
}
.profile-img-container svg{
  height: 120px;
  width: 120px;
}
.profile form, 
.profile button{
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: auto;
}
.profile p {
  display: inline-flex;
  color: var(--over-spread);
}
.profile em{
  margin-left: var(--space-unit);
  margin-right: var(--space-unit);
}
.profile .icon-edit{
  cursor: pointer;
}
.profile-sprite-table td{
  padding: calc(var(--space-unit)*1);
}
.profile-sprite-table thead {
  background-color: hsla(var(--o-spread), 0.25);
}
.profile-sprite-table td:not(:last-child){
  padding-right: calc( var(--space-unit)*2);
}

.profile .table-url-plus{
  position: absolute;
  width: calc(var(--size-unit)*12);
  padding: 8px;
  right: calc(var(--size-unit)*12*-1);
  top: 0;
  border-radius: 4px;
  opacity: 0;
  text-align: center;
  transition: opacity .24s ease;
  box-shadow: 0 0 0 1px var(--color-o-spread);
  background-color: var(--color-spread);
  color: var(--color-o-spread);
}
.profile .table-row:nth-child(even){
  background-color: hsla(var(--o-spread), 0.05);
}
.profile .table-row:hover .table-url-plus{
  opacity: 1;
}
.profile .table-row:hover .table-url-plus:hover{
  opacity: 1;
}
.profile .table-row.--selected .table-url-plus{
  display: none;
}
.profile .table-row .table-url-plus ~ .table-url-field{
  display: none;
}
.profile .table-row.--selected .table-url-plus ~ .table-url-field, 
.profile .table-row.--selected .table-url-validate{
  display: unset;
}
.profile .table-row.--selected .table-url-validate{
  opacity: 1;
  display: unset;
}
.profile .table-url-validate{
  position: absolute;
  display: none;
  top: 2px;
  width: calc(var(--size-unit)*8);
  right: calc(var(--size-unit)*8*-1);
  background-color: var(--color-o-spread);
  color: var(--color-spread);
  padding: 8px;
  border-radius: 4px;
  opacity: 0;
  transition: opacity .24s .4s ease;
  text-align: center;
}
.profile .delete-icon{
  width: calc(var(--size-unit) *  2);
  height: calc(var(--size-unit) * 2);
  box-shadow: 0 0 0 1px var(--color-o-spread);
  border-radius: 50%;
  padding: 2px;
  margin-left: calc(var(--space-unit)*4);
  opacity: 0;
  transition: opacity .24s ease;
}
.profile .table-url-item:hover .delete-icon,
.delete-icon:hover{
  opacity: 1;
}
</style>
